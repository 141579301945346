import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import { ErrorBoundary } from 'react-error-boundary';

import { App } from 'app';

const render = () => {
  const container = document.getElementById('root')!;

  if (!container) {
    throw new Error('Failed to find the root element');
  }

  const root = createRoot(container);

  root.render(
    <StrictMode>
      <ErrorBoundary FallbackComponent={({ error }) => error.message}>
        <App />
      </ErrorBoundary>
    </StrictMode>
  );
};

render();
